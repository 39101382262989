import * as React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
  
export default  function About() {
    return (
      <>
      <Main>

      {/* <header class="masthead" style="background-image: url(https://bigdogpics.s3.amazonaws.com/about-bg.jpg); height:150px;" > */}
      {/* </header> */}

      {/* <div class="container" style="height:610px"> */}
            <div>
        <h1>  About Us:</h1>
        <h3>We are passionate about using the data that surrounds us during our daily lives to make better decisions.
            It is our goal to simplify and quantify the world around us. </h3>
        <h3> Here at Big Dog AI, we utilize data analysis to describe, visualize, and model our reality.
              </h3>
      </div>

      </Main>
        {/* <nav>
          <Link to="/">Home</Link>
        </nav> */}
        </>
    );
  }

const Main = styled.main`

`;