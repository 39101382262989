import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"; 

export default  function Footer() {
    return (
    <Nav className="menu">
    {/* <Link to="/">
        <button type="button">
            Big Dog AI!
        </button>
    </Link>
    <Link to="/about">
        <button type="button">
            About!
        </button>
    </Link>
    <Link to="/contact">
        <button type="button">
            Contact!
        </button>
    </Link> */}
        <ul className="list">
            <li className="items"><Link className="link" to="/">Home</Link></li>
            <li className="items"><Link className="link" to="/covid">Covid Data</Link></li>
            <li className="items"><Link className="link" to="/about">About</Link></li>
            <li className="items"><Link className="link" to="/contact">Contact</Link></li>
        </ul>
    </Nav>
    );
  }

  const Nav = styled.nav`
    background-color: blue;
    height:50px;
    text-decoration: none;
  

    

    .list{

        justify-content:center;
    
        display:flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-decoration: none;
        color:white
        .items{
            padding: 20px;
            text-decoration: none;
            Link{
                text-decoration: none;
            }
        }

          .link{
            text-decoration: none;
            margin: 0 30px;
            padding: 20px;
            color:white;
            Link:link {
                background-color: yellow;
                text-decoration: none;
              }
              
              Link:visited {
                background-color: cyan;
                text-decoration: none;
              }
              
              Link:hover {
                background-color: lightgreen;
                text-decoration: none;
              }
              
              Link:active {
                background-color: hotpink;
                text-decoration: none;
              }
          }
     
    `;
  