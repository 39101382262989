import * as React from "react";
import { useFormik } from 'formik';
import styled from "styled-components"; 

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 15) {
    errors.firstName = 'Must be 15 characters or less';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 20) {
    errors.lastName = 'Must be 20 characters or less';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.subject) {
    errors.subject = 'Required';
  }
  if (!values.content) {
    errors.content = 'Required';
  }
  return errors;
};


export default  function Contact() {
  // Pass the useFormik() hook initial form values, a validate function that will be called when
  // form values change or fields are blurred, and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
    validate,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

    return (
      <>
        <Main>
          <h2>Got Ideas?</h2>
          <p>
            We love good ideas!
          </p>
        </Main>
      <Div>
    <form onSubmit={formik.handleSubmit}>
      <label htmlFor="firstName">First Name</label>
      <input
        id="firstName"
        name="firstName"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.firstName}
      />
      {formik.errors.firstName ? <div>{formik.errors.firstName}</div> : null}

      <label htmlFor="lastName">Last Name</label>
      <input
        id="lastName"
        name="lastName"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.lastName}
      />
      {formik.errors.lastName ? <div>{formik.errors.lastName}</div> : null}

      <label htmlFor="email">Email Address</label>
      <input
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
      />
      {formik.errors.email ? <div>{formik.errors.email}</div> : null}

      <label htmlFor="subject">Subject</label>
      <input
        id="subject"
        name="subject"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.subject}
      />
      {formik.errors.subject ? <div>{formik.errors.subject}</div> : null}

      <label htmlFor="content">Message</label>
      <textarea
        id="content"
        name="content"
        type="textarea"
        rows="4"
        onChange={formik.handleChange}
        value={formik.values.content}
      />
      {formik.errors.content ? <div>{formik.errors.content}</div> : null}

      <button type="submit">Submit</button>
    </form>
    </Div>
        {/* <nav>
          <Link to="/">Home</Link>
        </nav> */}
      </>
    );
  }

  const Main = styled.main`

  `;
const Div = styled.div`
  text-align:left;

  label{
    display:block;
  }
  button{
    display:block;
  }
`;


//   <div class="input-group-custom">
//   <div><label for="name">Name</label></div>
//   <div><input type="text" name="name" id="name" required></div>
// </div>
// <div class="input-group-custom">
// <div><label for="address">Email Address</label></div>
// <div><input type="text" name="address" id="address" required></div>
// </div>
// <div class="input-group-custom">
//   <div><label for="subject">Subject</label></div>
//   <div><input type="text" name="subject" id="subject" required></div>
// </div>
// <div class="input-group-custom">
//   <div><label for="content">Message</label></div>
//   <div>
//       <textarea name="content" id="content" cols="30" rows="10" required></textarea>
//   </div>
// </div>
// <input type="submit" value="send email">