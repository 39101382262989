import * as React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components"; 

export default  function CovidData() {
    return (
      <>
        <Main>
          <h2>It may be a real thing</h2>
          <p>
            Data coming soon
          </p>
        </Main>
        {/* <nav>
          <Link to="/">Home</Link>
        </nav> */}
      </>
    );
  }

  const Main = styled.main`

  `;