import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"; 

export default  function NavBar() {
    return (
    <Nav className="menu">
    <Link to="/">
        <button type="button">
            Big Dog AI
        </button>
    </Link>
    <Link to="/covid">
        <button type="button">
            Covid Data
        </button>
    </Link>
    <Link to="/about">
        <button type="button">
            About
        </button>
    </Link>
    <Link to="/contact">
        <button type="button">
            Contact
        </button>
    </Link>

        {/* <ul className="list">
            <li className="items"><Link to="/">Home</Link></li>
            <li className="items"><Link to="/about">About</Link></li>
            <li className="items"><Link to="/contact">Contact</Link></li>
        </ul> */}
    </Nav>
    );
  }

  const Nav = styled.nav`
    background-color: coral;
    height:50px;
    text-decoration: none;


    text-align: center;

    button{
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        margin: 0 15px;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;

        :hover {
            background-color: hotpink;
            text-decoration: none;
          }
    }
    `;
  