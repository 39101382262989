import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./App.css";
import Home from './pages/Home';
import About from "./pages/About";
import Contact from "./pages/Contact";
import CovidData from "./pages/CovidData";
import FlippersFriend from "./pages/FlippersFriend";
import styled from "styled-components";
// import FormikExample from "./pages/FormikExample";

function App() {
  return (
    <Div className="App">
      <Helmet>
          <meta 
            charSet="utf-8" 
            />
            <meta 
            name="keywords"  
            content = "Big Dog AI, Flippers Friend, Data Consultants"
            />
          <meta 
            name="description"  
            content = "Harness the power of your data!"
            />

          <title>Big Dog AI</title>
          {/* <link rel="canonical" href="" /> */}
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="covid" element={<CovidData />} />
        <Route path="flippersfriend" element={<FlippersFriend />} />
        {/* <Route path="form" element={<FormikExample />} /> */}
      </Routes>
    </Div>
  );
}

export default App;

const getHeight = () => {
  let height = window.innerHeight - 120;
  return height+"px"
}


const Div = styled.div`
  min-height:${getHeight};
`;