import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
// import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <BrowserRouter>
    <NavBar />
    <App />
    <Footer/>
  </BrowserRouter>,
  document.getElementById("root")
);