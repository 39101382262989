import * as React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components"; 

export default  function FlippersFriend() {
    return (
      <>
        <Main>
          <h2>Flippers Friend</h2>
          <p>
            Simplify managing tasks and priorities, budget management, managing bids, contact tracking, complete paperless solution.  
          </p>
        </Main>
      </>
    );
  }

  const Main = styled.main`

  `;