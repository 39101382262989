import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Home() {
  const api = "https://ngpvqs3w71.execute-api.us-east-1.amazonaws.com/production";
  console.log(api)
  
    return (
      <>
        <Main>
          <h1>Welcome to the New Home of Big Dog AI!</h1>
          <h2>We are updating our system.  We hope to be back by Wednesday 22 December.</h2>
          <h2>COMING SOON: vaccine data, infection rates and other important statistics. <Link to="/covid">Link to Covid Data</Link></h2>
          <h2>We are also looking for great data ideas.  If you have any suggestions for our next project, <Link to="/contact">we would love to hear your idea</Link></h2>
          <h2>We are pleased to announce we will be the future home of FlippersFriend <Link to="/flippersfriend">Link to Flippers Friend</Link></h2>
          <p>Stay Tuned: This is going to be epic!</p>
        </Main>
      </>
    );
  }
  
 const Main = styled.main`

 `;